<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="36"
      height="36"
      rx="18"
      fill="#0073A8"
    />
    <path
      d="M19 8C19 7.44772 18.5523 7 18 7C17.4477 7 17 7.44772 17 8H19ZM17 28C17 28.5523 17.4477 29 18 29C18.5523 29 19 28.5523 19 28H17ZM8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19V17ZM28 19C28.5523 19 29 18.5523 29 18C29 17.4477 28.5523 17 28 17V19ZM17 8V18H19V8H17ZM17 18V28H19V18H17ZM18 17H8V19H18V17ZM8 19H28V17H8V19Z"
      fill="white"
    />
  </svg>
</template>

import Fuse from 'fuse.js'

export function useFuse(searchableContent: any[], options: any) {
  const fuse = new Fuse(searchableContent, options)

  function search(query: string) {
    if (!query.trim()) {
      return []
    }
    return fuse.search(query).map(result => result.item)
  }

  return { search }
}

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.249599 22.536L7.3296 15.4608C5.9664 13.824 5.1456 11.7216 5.1456 9.432C5.1408 4.2288 9.3744 0 14.5728 0C19.7712 0 24 4.2288 24 9.4272C24 14.6256 19.7712 18.8544 14.5728 18.8544C12.2832 18.8544 10.1808 18.0336 8.544 16.6704L1.464 23.7504C1.296 23.9184 1.0752 24 0.8592 24C0.6432 24 0.4224 23.9184 0.2544 23.7504C-0.0816002 23.4144 -0.0816002 22.872 0.249599 22.536ZM22.2864 9.4272C22.2864 5.1744 18.8256 1.7136 14.5728 1.7136C10.32 1.7136 6.8592 5.1744 6.8592 9.4272C6.8592 13.68 10.32 17.1408 14.5728 17.1408C18.8256 17.1408 22.2864 13.68 22.2864 9.4272Z"
      fill="#000066"
    />
  </svg>
</template>

import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const isProd = config.public.appEnv !== 'development'
  
  Sentry.init({
    dsn: "https://6579b5d478c8c3cb27aea6b675ec20ee@o45513.ingest.us.sentry.io/4508138852515840",
    enabled: isProd,
    environment: config.public.appEnv,
    denyUrls: [/https?:\/\/cdn\.sp-selfservice\.com/, /https?:\/\/www\.o2\.co\.uk/], 
  });
})

export const useTopicStore = defineStore('topicStore', () => {
    const topic = ref<Topic | null>(null)
  
    async function fetchTopic(brand: string, model: string, topicSlug: string) {
      try {
        if (topic.value?.seo.slug === topicSlug) return
  
        const data = await $fetch(`/api/topic`, {
            query: {
                brand: brand,
                model: model,
                topic: topicSlug,
              },
        })
        topic.value = data
      } catch (error) {
        console.log(error)
      }
    }
  
    return {
        topic,
        fetchTopic
    }
  })
  
<template>
  <div class="spinner-backdrop">
    <img
      src="/assets/images/loader.gif"
      alt="loader"
    />
  </div>
</template>

<style>
.spinner-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 190;
}

.spinner-backdrop img {
  display: inline-block;
  position: absolute;
  width: 200px;
  height: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}
</style>

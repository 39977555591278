export const useDevicesStore = defineStore('devicesStore', () => {
  const devices = ref<Product[]>([])
  const brands = ref<Brand[]>([])
  const topicName = ref<string | null>(null)

  const detectionDevices = ref<Product[]>([])
  const detectionBrand = ref()

  const isLoading = ref(false)

  async function fetch() {
    isLoading.value = true

    try {
      const route = useRoute()
      const data = await $fetch('/api/devices', { query: route.query })

      devices.value = data.devices
      brands.value = data.brands
      topicName.value = null

      if (route.query.guide || route.query.problem) {
        topicName.value = data.topicName
      }
    } catch (error) {
      console.log(error)
    } finally {
      isLoading.value = false
    }
  }
  async function fetchDetectionDevices() {
    const { browserResolve, hardwareInfoStr } = getDetectionDevices()
    try {
      const data = await browserResolve

      const res = (await $fetch(`https://wm-api.com/detect/me`, {
        query: {
          _: new Date().getTime(),
          'api-key': '287f43e0-3f4b-4988-b259-066bb706afcb',
          modelIdentifier: data?.model || '',
          hardwareInfo: hardwareInfoStr,
          version: 2,
        },
      })) as { [key: string]: any }

      let listDevices = []
      if (res?.Device != null) {
        listDevices.push(res.Device)
      } else if (res.AlternativeDevices != null) {
        listDevices = res.AlternativeDevices
      } else if (res.SuggestedManufacturer) {
        detectionBrand.value = res.SuggestedManufacturer
      }
      if (listDevices.length) {
        listDevices.forEach((d: any) => {
          d.Id = fixWmAdminDeviceId(d.Id)
        })
        detectionDevices.value = devices.value.filter((d) => listDevices.find((item: any) => item.Id === d.id))
      }
    } catch (error) {}
  }

  const onlineBrands = computed(() => brands.value.filter((x) => x.online).sort((a, b) => a.name.localeCompare(b.name)))
  const onlineDevices = computed(() => devices.value.filter((x) => x.online))

  return {
    brands,
    devices,
    topicName,
    onlineDevices,
    onlineBrands,
    detectionDevices,
    detectionBrand,
    isLoading,
    fetch,
    fetchDetectionDevices,
  }
})

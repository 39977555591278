import { default as indexBdLjzdTwBjMeta } from "/home/appveyor/projects/o2-uk/pages/[brand]/[model]/[section]/[topic]/index.vue?macro=true";
import { default as index3qnwPmLhfrMeta } from "/home/appveyor/projects/o2-uk/pages/[brand]/[model]/[section]/index.vue?macro=true";
import { default as specifications4Q8UDumfKFMeta } from "/home/appveyor/projects/o2-uk/pages/[brand]/[model]/specifications.vue?macro=true";
import { default as _91model_93Jpwe1ISSuZMeta } from "/home/appveyor/projects/o2-uk/pages/[brand]/[model].vue?macro=true";
import { default as indexPAqGf74miOMeta } from "/home/appveyor/projects/o2-uk/pages/[brand]/index.vue?macro=true";
import { default as indexZRXYmFJ5jdMeta } from "/home/appveyor/projects/o2-uk/pages/index.vue?macro=true";
export default [
  {
    name: "brand-model",
    path: "/:brand()/:model()",
    meta: _91model_93Jpwe1ISSuZMeta || {},
    component: () => import("/home/appveyor/projects/o2-uk/pages/[brand]/[model].vue").then(m => m.default || m),
    children: [
  {
    name: "brand-model-section-topic",
    path: ":section()/:topic()",
    component: () => import("/home/appveyor/projects/o2-uk/pages/[brand]/[model]/[section]/[topic]/index.vue").then(m => m.default || m)
  },
  {
    name: "brand-model-section",
    path: ":section()",
    component: () => import("/home/appveyor/projects/o2-uk/pages/[brand]/[model]/[section]/index.vue").then(m => m.default || m)
  },
  {
    name: "brand-model-specifications",
    path: "specifications",
    component: () => import("/home/appveyor/projects/o2-uk/pages/[brand]/[model]/specifications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "brand",
    path: "/:brand()",
    meta: indexPAqGf74miOMeta || {},
    component: () => import("/home/appveyor/projects/o2-uk/pages/[brand]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexZRXYmFJ5jdMeta || {},
    component: () => import("/home/appveyor/projects/o2-uk/pages/index.vue").then(m => m.default || m)
  }
]
<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.7113 0H6.2823C4.39505 0 2.85327 1.54178 2.85327 3.42903V28.571C2.85327 30.4582 4.39505 32 6.2823 32H25.7113C27.5986 32 29.1404 30.4582 29.1404 28.571V3.42903C29.1404 1.54178 27.5986 0 25.7113 0ZM6.2823 2.28389H25.7113C26.3447 2.28389 26.8565 2.80208 26.8565 3.42903V3.9984H5.14356V3.42903C5.14356 2.80208 5.65535 2.28389 6.2823 2.28389ZM26.8501 6.28229V25.7113H5.14356V6.28229H26.8501ZM25.7113 29.7097H6.2823C5.64895 29.7097 5.13716 29.1915 5.13716 28.5646V27.9952H26.8501V28.5646C26.8501 29.1979 26.3383 29.7097 25.7113 29.7097Z"
      fill="#000066"
    />
  </svg>
</template>

<template>
  <Swiper
    v-bind="props.swiperParams"
    :modules="modules"
    @swiper="setSwiper"
    @slide-change="onSlideChange"
  >
    <template v-slot:container-start>
      <slot name="outer-container-start"> </slot>
    </template>

    <template v-slot:container-end>
      <slot name="outer-container-end"> </slot>
    </template>

    <template v-slot:wrapper-start>
      <slot name="outer-wrapper-start"></slot>
      <slot></slot>
    </template>

    <template v-slot:wrapper-end>
      <slot name="outer-wrapper-end"></slot>
    </template>
  </Swiper>
</template>

<script lang="ts" setup>
import { Swiper } from 'swiper/vue'
import type { Swiper as SwiperType, SwiperOptions } from 'swiper/types'
import { Pagination, Zoom } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/zoom'
const props = defineProps({
  activeIndex: { type: Number, default: 0 },
  swiperParams: { type: Object, default: {} as SwiperOptions },
  slides: { type: Array },
  modules: { type: Array },
})
const emit = defineEmits<{
  (e: 'swiper', swiper: SwiperType): void
  (e: 'slide-change'): void
  (e: 'update:active-index', index: number): void
}>()
const swiper = ref<SwiperType>()
const modules = ref(props.modules?.length ? [...props.modules] : [Pagination, Zoom])
const activeIndex = computed(() => {
  return swiper.value?.activeIndex
})
const setSwiper = (s: SwiperType) => {
  swiper.value = s
  swiper.value.slideTo(props.activeIndex, 0)
  emit('swiper', swiper.value)
}
const slideTo = (index: number) => {
  if (!swiper.value) return
  swiper.value.slideTo(index)
}
const slidePrev = () => {
  if (!swiper.value) return
  swiper.value.slidePrev()
}
const slideNext = () => {
  if (!swiper.value) return
  swiper.value.slideNext()
}
const onSlideChange = () => {
  emit('slide-change')
}
watch(activeIndex, (value) => emit('update:active-index', value || 0))
watch(
  () => props.activeIndex,
  (value) => slideTo(value)
)
</script>

<style scoped>
.swiper {
  position: initial;
  width: 100%;
}
</style>

<style>
</style>
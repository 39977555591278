<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.5844 0H20.6344C18.7595 0 17.2261 1.54296 17.2261 3.42962V4H4.74147C2.86659 4 1.33325 5.54296 1.33325 7.42962V28.5704C1.33325 30.457 2.86659 32 4.74147 32H20.6416C22.5165 32 24.0498 30.457 24.0498 28.5704V22.8592H28.5917C30.4666 22.8592 31.9999 21.3163 31.9999 19.4296V3.42962C31.9926 1.54296 30.4593 0 28.5844 0ZM19.5007 5.71846H29.7181V17.1408H19.5007V5.71846ZM20.6344 2.28885H28.5844C29.2094 2.28885 29.7181 2.80073 29.7181 3.42962V4H19.5007V3.42962C19.5007 2.80073 20.0094 2.28885 20.6344 2.28885ZM4.74147 6.28885H17.2334V8H3.60055V7.42962C3.60055 6.85923 4.16738 6.28885 4.74147 6.28885ZM20.6344 29.7112H4.74147C4.17464 29.7112 3.60782 29.1408 3.60782 28.5704V28H21.7753V28.5704C21.768 29.1408 21.2012 29.7112 20.6344 29.7112ZM21.768 26.2815H3.60055V9.71846H17.2261V19.4296C17.2261 21.3163 18.7595 22.8592 20.6344 22.8592H21.768V26.2815ZM28.5844 20.5704H20.6344C20.0094 20.5704 19.5007 20.0585 19.5007 19.4296V18.8592H29.7181V19.4296C29.7181 20.0585 29.2094 20.5704 28.5844 20.5704Z"
      fill="#000066"
    />
  </svg>
</template>

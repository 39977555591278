<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 6.5136L9.5136 0H21V24H3V6.5136ZM9 6V2.9136L5.9136 6H9ZM19.2864 22.2864H4.7136V7.7136H10.7136V1.7136H19.2864V22.2864ZM7.2864 13.2864C6.816 13.2864 6.4272 13.6704 6.4272 14.1456C6.4272 14.6208 6.8112 15.0048 7.2864 15.0048H16.7136C17.184 15.0048 17.5728 14.6208 17.5728 14.1456C17.5728 13.6704 17.1888 13.2864 16.7136 13.2864H7.2864ZM16.7136 17.5728H7.2864C6.816 17.5728 6.4272 17.9568 6.4272 18.432C6.4272 18.9024 6.8112 19.2912 7.2864 19.2912H16.7136C17.184 19.2912 17.5728 18.9072 17.5728 18.432C17.5728 17.9568 17.184 17.5728 16.7136 17.5728Z"
      fill="#0073A8"
    />
  </svg>
</template>

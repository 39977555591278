export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const isProd = config.public.appEnv !== 'development'
  const enabled = isProd
  const debug = true
  const router = useRouter()

  function trackPage(path = '', ...sections: string[]) {
    const sectionsString = sections.length > 0 ? `|${sections.join('|')}` : ''

    const utag_data: Record<string, any> = {
      page_page_name: 'portal:con|help|devicehelp' + sectionsString,
      page_channel: 'portal:con',
      page_cms: 'speedperform',
    }
    sections.forEach((section, index) => {
      utag_data[`page_sitesection${index + 1}`] = section
    })

    window.utag_data = utag_data

    debug && console.log(window.utag)

    if (enabled && window.utag) {
      window.utag.view(utag_data)
      debug && console.log('Tealium tracking:', utag_data)
    }
  }

  function trackEvent(event: any, page = '') {
    window.utag.link({
      tealium_event: event,
      page_name: page,
    })
  }

  router.afterEach((to) => {
    const { path } = to
    const sections = path.split('/').filter((s) => s !== null && s.length > 1)
    enabled && trackPage(path, ...sections)
  })

  return {
    provide: {
      trackEvent,
    },
  }
})


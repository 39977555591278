<template>
  <button
    :type="type"
    :disabled="disabled"
    :class="[
      'base-btn',
      'inline-block w-full max-w-[240px] rounded-lg px-3 md:px-6 text-center font-bold text-base outline-none',
      variantClass,
    ]"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
const props = defineProps({
  variant: {
    type: String,
    default: 'outline',
  },
  type: {
    type: String as PropType<'submit' | 'reset' | 'button'>,
    default: 'button',
  },
  disabled: Boolean,
})

const defaultVariant = {
  primary: 'bg-mid-blue text-white hover:bg-[#002E80] active:bg-primary py-4 focus:outline-primary',
  outline:
    'border-mid-blue py-[14px] text-mid-blue hover:bg-[#E5F1F7] hover:border-primary border-2 hover:text-primary active:border-primary active:bg-primary active:text-white focus:outline-primary',
} as { [key: string]: string }

const variantClass = computed(() => {
  return defaultVariant[props.variant]
})
</script>

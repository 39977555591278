import { createPlugin, wmSampleFunc, wmSetDeviceFunc } from '@worldmanuals/sp-sampling'
import type { TPluginOptions } from '@worldmanuals/sp-sampling/dist/index.d.ts'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const { apiUrl, apiClientId, apiChannelId, apiLanguageId, samplingClientId }: { [key: string]: any } = runtimeConfig.public

  const options: TPluginOptions = {
    enabled: true,
    apiUrl: (apiUrl as string).replace(/\/?$/, '/'),
    client: { id: apiClientId, cmtReferenceId: samplingClientId },
    applicationId: 2,
    channelId: apiChannelId,
    languageId: apiLanguageId,
  }

  const Sampling = createPlugin(options)
  nuxtApp.vueApp.use(Sampling)

  return {
    provide: {
      wmSetDevice: wmSetDeviceFunc,
      wmSample: wmSampleFunc,
    },
  }
})

import * as Sentry from '@sentry/nuxt'

if (process.env.NUXT_SENTRY_DSN) {
  console.log('Sentry')
  Sentry.init({
    dsn: process.env.NUXT_SENTRY_DSN,
    environment: process.env.NUXT_APP_ENV,
    allowUrls: [/https?:\/\/((cdn|api)\.)?sp-selfservice\.com/],
  })
}

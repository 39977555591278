<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8544 0H21.1456C24.2944 0 26.8544 2.5664 26.8544 5.7152V26.2848C26.8544 29.4336 24.288 32 21.1392 32H10.8544C7.70556 32 5.13916 29.4336 5.13916 26.2848V5.7152C5.13916 2.5664 7.70556 0 10.8544 0ZM7.43036 7.4304V24.5696V24.576H24.576V7.4304H7.43036ZM10.8544 2.2848H21.1392C22.8352 2.2848 24.2432 3.52 24.5184 5.1392H7.47516C7.75036 3.5264 9.15836 2.2848 10.8544 2.2848ZM10.8544 29.7152H21.1456C22.8416 29.7152 24.2496 28.4736 24.5184 26.8608H7.47516C7.75036 28.48 9.15836 29.7152 10.8544 29.7152Z"
      fill="#000066"
    />
  </svg>
</template>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="10"
      transform="matrix(-1 0 0 1 22 2)"
      fill="url(#paint0_linear_524_2508)"
    />
    <path
      d="M14 7L9 12L14 17"
      stroke="white"
      stroke-width="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_524_2508"
        x1="10"
        y1="0"
        x2="10"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#469FD7" />
        <stop
          offset="1"
          stop-color="#347FB4"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

import validate from "/home/appveyor/projects/o2-uk/node_modules/nuxt/dist/pages/runtime/validate.js";
import trailing_45slash_45global from "/home/appveyor/projects/o2-uk/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/home/appveyor/projects/o2-uk/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "topic-redirect": () => import("/home/appveyor/projects/o2-uk/middleware/topic-redirect.ts")
}
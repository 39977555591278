<template>
  <transition
    enter-active-class="enter-active"
    leave-active-class="leave-active"
    @before-enter="beforeEnter($event as HTMLElement)"
    @enter="enter($event as HTMLElement)"
    @after-enter="afterEnter($event as HTMLElement)"
    @before-leave="beforeLeave($event as HTMLElement)"
    @leave="leave($event as HTMLElement)"
    @after-leave="afterLeave($event as HTMLElement)"
  >
    <slot />
  </transition>
</template>

<script setup lang="ts">
const beforeEnter = (element: HTMLElement) => {
  requestAnimationFrame(() => {
    if (!element.style.height) {
      element.style.height = '0px'
    }
    element.style.display = ''
  })
}

const enter = (element: HTMLElement) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      element.style.height = `${element.scrollHeight}px`
    })
  })
}

const afterEnter = (element: HTMLElement) => {
  element.style.height = ''
}

const beforeLeave = (element: HTMLElement) => {
  requestAnimationFrame(() => {
    if (!element.style.height) {
      element.style.height = `${element.offsetHeight}px`
    }
  })
}

const leave = (element: HTMLElement) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      element.style.height = '0px'
    })
  })
}

const afterLeave = (element: HTMLElement) => {
  element.style.height = ''
}
</script>

<style>
.enter-active,
.leave-active {
  overflow: hidden;
  transition: height 0.2s linear;
}
</style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      fill="url(#paint0_linear_1664_11138)"
    />
    <path
      d="M10 7L15 12L10 17"
      stroke="white"
      stroke-width="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1664_11138"
        x1="12"
        y1="2"
        x2="12"
        y2="22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#469FD7" />
        <stop
          offset="1"
          stop-color="#347FB4"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

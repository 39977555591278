<template>
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4384 0H14.4758C13.0697 0 11.9197 1.15722 11.9197 2.57221V3H2.55616C1.15 3 0 4.15722 0 5.57221V21.4278C0 22.8428 1.15 24 2.55616 24H14.4813C15.8874 24 17.0374 22.8428 17.0374 21.4278V17.1444H20.4438C21.85 17.1444 23 15.9872 23 14.5722V2.57221C22.9946 1.15722 21.8445 0 20.4384 0ZM13.6256 4.28885H21.2886V12.8556H13.6256V4.28885ZM14.4758 1.71664H20.4384C20.9071 1.71664 21.2886 2.10055 21.2886 2.57221V3H13.6256V2.57221C13.6256 2.10055 14.0071 1.71664 14.4758 1.71664ZM2.55616 4.71664H11.9251V6H1.70047V5.57221C1.70047 5.14442 2.12559 4.71664 2.55616 4.71664ZM14.4758 22.2834H2.55616C2.13104 22.2834 1.70592 21.8556 1.70592 21.4278V21H15.3315V21.4278C15.3261 21.8556 14.9009 22.2834 14.4758 22.2834ZM15.3261 19.7112H1.70047V7.28885H11.9197V14.5722C11.9197 15.9872 13.0697 17.1444 14.4758 17.1444H15.3261V19.7112ZM20.4384 15.4278H14.4758C14.0071 15.4278 13.6256 15.0439 13.6256 14.5722V14.1444H21.2886V14.5722C21.2886 15.0439 20.9071 15.4278 20.4384 15.4278Z"
      fill="#0073A8"
    />
  </svg>
</template>

<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="#000066"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.88971 30H4.93382C3.31352 30 2 28.6569 2 27V14.24C2 13.6877 2.43784 13.24 2.97794 13.24C3.51804 13.24 3.95588 13.6877 3.95588 14.24V27C3.95588 27.5523 4.39372 28 4.93382 28H6.88971C7.42981 28 7.86765 27.5523 7.86765 27V5C7.86765 4.44772 7.42981 4 6.88971 4H4.93382C4.39372 4 3.95588 4.44772 3.95588 5V10.285C3.95588 10.8373 3.51804 11.285 2.97794 11.285C2.43784 11.285 2 10.8373 2 10.285V5C2 3.34315 3.31352 2 4.93382 2H6.88971C8.51001 2 9.82353 3.34315 9.82353 5V27C9.82353 28.6569 8.51001 30 6.88971 30Z"
      fill="#000066"
    ></path>
    <path
      d="M20.7702 17.2354H20.7453C19.3615 17.2437 18.0367 17.7906 17.0578 18.7576C16.9074 18.9067 16.8231 19.1087 16.8235 19.3192C16.8207 19.5343 16.9069 19.7412 17.0622 19.8919C17.2175 20.0426 17.4283 20.1241 17.6458 20.1173C17.861 20.1172 18.0673 20.0321 18.2188 19.8808C18.8858 19.2042 19.7996 18.8208 20.7552 18.8167H20.7802C21.7166 18.8261 22.6117 19.1995 23.2717 19.8562C23.4193 20.0101 23.6253 20.0958 23.8398 20.0927C23.9955 20.092 24.1484 20.0512 24.2833 19.9744L24.3531 19.94L24.4129 19.8808C24.7251 19.5681 24.7251 19.0654 24.4129 18.7527C23.4494 17.7906 22.1396 17.245 20.7702 17.2354Z"
      fill="#000066"
    ></path>
    <path
      d="M20.7472 14.3525C18.57 14.3521 16.4904 15.2113 15.0051 16.7249C14.8512 16.8708 14.7647 17.069 14.7647 17.2757C14.7647 17.4824 14.8512 17.6805 15.0051 17.8265C15.1602 17.971 15.3673 18.054 15.5844 18.0584C15.8053 18.0598 16.0169 17.9741 16.1688 17.8217C17.3552 16.6196 19.0084 15.9332 20.7421 15.9228C22.4781 15.9228 24.1355 16.611 25.3155 17.8217C25.6381 18.1133 26.1461 18.1083 26.4622 17.8103C26.7784 17.5123 26.7881 17.0293 26.4842 16.72C25.0003 15.208 22.9222 14.3504 20.7472 14.3525Z"
      fill="#000066"
    ></path>
    <path
      d="M27.6701 14.065C25.8648 12.5932 23.6593 11.708 21.3447 11.5262C18.4529 11.2239 15.569 12.1661 13.4026 14.1209C13.2384 14.2573 13.1366 14.4553 13.1206 14.6692C13.102 14.8868 13.1709 15.1027 13.312 15.2684C13.4588 15.452 13.6733 15.5674 13.9063 15.5883H13.9818C14.1887 15.5912 14.3868 15.5043 14.5257 15.3496C16.347 13.729 18.7488 12.9341 21.1684 13.151C23.1471 13.298 25.0349 14.0469 26.5823 15.2989C26.7432 15.4444 26.9559 15.5178 27.1715 15.502C27.3856 15.4879 27.5867 15.3937 27.7356 15.2379L27.7708 15.1922C28.0321 14.847 27.9884 14.3576 27.6701 14.065Z"
      fill="#000066"
    ></path>
    <path
      d="M26.9508 7.1542V4.98032C26.9371 3.33029 25.607 2 23.971 2H16.9885C15.3641 2.01633 14.052 3.34194 14.0386 4.98032V7.18426C12.3033 7.62329 11.0795 9.1868 11.0588 10.9911V21.2694C11.0622 23.14 12.3591 24.7548 14.1727 25.1463V27.0197C14.1862 28.66 15.5012 29.9864 17.1276 30H24.1349C25.7709 30 27.101 28.6697 27.1146 27.0197V25.0962C28.8178 24.6197 29.9975 23.0571 30 21.2744V10.9911C29.9868 9.15435 28.7253 7.56696 26.9508 7.1542ZM16.9885 3.55277H24.0058C24.771 3.55277 25.3914 4.17845 25.3914 4.95027V7.04401H15.6029V4.95027C15.6029 4.17845 16.2233 3.55277 16.9885 3.55277ZM24.1299 28.3921H17.1127C16.3466 28.3894 15.7271 27.7623 15.7271 26.9896V25.2315H25.5205V26.9896C25.5178 27.7631 24.8968 28.3894 24.1299 28.3921ZM28.4108 21.2694C28.4081 22.5851 27.3513 23.6509 26.0469 23.6537H14.9722C13.6667 23.6537 12.6083 22.5862 12.6083 21.2694V10.9911C12.6083 9.67427 13.6667 8.6068 14.9722 8.6068H26.0469C27.3513 8.60955 28.4081 9.67541 28.4108 10.9911V21.2694Z"
      fill="#000066"
    ></path>
    <path
      d="M6.11768 25.0585C6.79991 25.0585 7.35297 24.5054 7.35297 23.8232C7.35297 23.141 6.79991 22.5879 6.11768 22.5879C5.43545 22.5879 4.88239 23.141 4.88239 23.8232C4.88239 24.5054 5.43545 25.0585 6.11768 25.0585Z"
      fill="#000066"
    ></path>
  </svg>
</template>

export default defineNuxtRouteMiddleware((to) => {
  const { path, query, hash } = to

  if (path === '/' || !path.endsWith('/')) return

  const nextPath = path.replace(/\/+$/, '') || '/'
  const nextRoute = { path: nextPath, query, hash }

  return navigateTo(nextRoute, { redirectCode: 301 })
})
